import { HTMLAttributes, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { merge, pick } from 'remeda'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/custom/button'
import { cn } from '@/lib/utils'
import { sendOtpSchema, verifyOtpSchema } from '@/pages/auth/components/schema'
import { ISendOtpReq, IVerifyOtpReq } from '@/utils/types'
import { useSendOtpUserMutation, useVerifyOtpUserMutation } from '@/hooks/api/useUser'
import { PinInput, PinInputField } from '@/components/custom/pin-input'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from '@/components/ui/checkbox'
import toast from 'react-hot-toast'
// import { eventDispatcher } from '@/lib/events'

interface UserAuthFormProps extends HTMLAttributes<HTMLDivElement> { }

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const navigate = useNavigate()
  const [loginStep, setLoginStep] = useState('email')
  const [resendCountdown, setResendCountdown] = useState(0)

  const sendOtp = useSendOtpUserMutation({
    onSuccess: () => {
      setLoginStep('otp')
      setResendCountdown(60)
    },
    onError: (error: any) => {
      sendOtpMethods.setError('email', {
        type: 'manual',
        message: error,
      })
    },
  })
  const verifyOtp = useVerifyOtpUserMutation({
    onSuccess: async (data: any) => {
      if (data?.data?.data?.token) {
        const {
          data: {
            data: { token, email },
          },
        } = data
        try {
          await localStorage.setItem('token', token)
          await localStorage.setItem('email', email)
        } catch (err) {
          console.log(err)
        }

        toast.success('Successfully verify OTP, Navigating to Dashboard ...')

        setTimeout(() => {
          window.location.pathname = '/dashboard'
          // navigate('/dashboard')
        }, 500)
      }
    },
    onError: (error: any) => {
      verifyOtpMethods.setError('otp', {
        type: 'manual',
        message: error,
      })
    },
  })
  // const isLoading = false

  const sendOtpMethods = useForm<ISendOtpReq>({
    resolver: zodResolver(sendOtpSchema),
  })

  const verifyOtpMethods = useForm<IVerifyOtpReq>({
    resolver: zodResolver(verifyOtpSchema),
  })

  const sendOtpHandler: SubmitHandler<ISendOtpReq> = (data: any) => {
    // eventDispatcher('SEND_OTP_BUTTON_CLICK', data)
    // const payload: any = {};
    console.log(data)
    sendOtp.mutateAsync(data)

    // if (Object?.keys(data)?.length <= 0) {
    //   toast.error("Please enter email or mobile number");
    //   return;
    // }

    // if (inputMode === "email") {
    //   payload.email = data.email;
    // } else {
    //   payload.mobile = data.mobile;
    // }

    // userLogin(payload);
  }

  const verifyOtpHandler: SubmitHandler<{ otp: string }> = (data) => {
    const obj = sendOtp?.data?.data?.data
    const payload: any = merge(data, pick(obj, ['email', 'otpToken', 'mobile']))
    // eventDispatcher('VERIFY_OTP_BUTTON_CLICK', payload)
    verifyOtp.mutateAsync(payload)
  }

  const isSendOtpButtonDisabled = !sendOtpMethods.formState.isValid || !sendOtpMethods.watch('email', '')

  const isVerifyOtpButtonDisabled = !verifyOtpMethods.formState.isValid || !verifyOtpMethods.watch('otp', '')

  const handleResendOtp = () => {
    const email = sendOtpMethods.getValues('email')
    // eventDispatcher('SEND_OTP_BUTTON_CLICK', { email: email })
    if (email) {
      sendOtpHandler({ email })
    }
  }

  useEffect(() => {
    if (resendCountdown > 0) {
      const timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000)
      return () => clearTimeout(timer)
    }
  }, [resendCountdown])

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      {loginStep === 'email' && (
        <Form {...sendOtpMethods}>
          <div className='flex flex-col space-y-2 text-left'>
            <h1 className='text-2xl font-semibold tracking-tight'>Log in to your account</h1>
            <p className='text-sm text-muted-foreground'>Welcome! Please enter your details.</p>
          </div>
          <form onSubmit={sendOtpMethods.handleSubmit(sendOtpHandler)}>
            <div className='grid gap-2'>
              <FormField
                control={sendOtpMethods.control}
                name='email'
                render={({ field, fieldState }) => (
                  <FormItem className='space-y-1'>
                    <FormLabel>Enter Email</FormLabel>
                    <FormControl>
                      <Input placeholder='Please enter Email address' {...field} hasError={!!fieldState.error} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {/* <div className=' flex items-center gap-2 text-sm text-gray-500'>
                <Checkbox />
                <p>I accept to receive promotional updates.</p>
              </div> */}

              <Button className='my-4' loading={sendOtp.isLoading} disabled={isSendOtpButtonDisabled}>
                Send OTP
              </Button>
              {/* <p className=' text-sm text-muted-foreground'>
                By continuing, you agree to our {' '}
                <a
                  href='/terms'
                  className='underline underline-offset-4 hover:text-primary'
                >
                  Terms & Conditions
                </a>{' '}
                .
              </p> */}
            </div>
          </form>
        </Form>
      )}
      {loginStep === 'otp' && (
        <Form {...verifyOtpMethods}>
          <div className='flex flex-col space-y-2 text-left'>
            <h1 className='text-2xl font-semibold tracking-tight'>Verify with OTP</h1>
            <p className='text-sm text-muted-foreground'>Sent to {sendOtpMethods.watch('email', '')}</p>
          </div>
          <form onSubmit={verifyOtpMethods.handleSubmit(verifyOtpHandler)}>
            <div className='grid gap-2'>
              <FormField
                control={verifyOtpMethods.control}
                name='otp'
                render={({ field }) => (
                  <FormItem className='space-y-1'>
                    {/* <FormLabel>OTP</FormLabel> */}
                    <FormControl>
                      <PinInput
                        {...field}
                        className='flex h-10 justify-between'
                        type='numeric'
                      // onComplete={() => setDisabledBtn(false)}
                      // onIncomplete={() => setDisabledBtn(true)}
                      >
                        {Array.from({ length: 6 }, (_, i) => {
                          return (
                            <PinInputField
                              key={i}
                              component={Input}
                              className={`${verifyOtpMethods.getFieldState('otp').invalid ? 'border-red-500' : ''}`}
                            />
                          )
                        })}
                      </PinInput>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className='flex items-center'>
                <p className='my-2 text-sm text-slate-500'>Didn’t receive the OTP?</p>
                <div
                  onClick={resendCountdown === 0 ? handleResendOtp : undefined}
                  className={`ml-2 text-sm  text-slate-800 underline underline-offset-4 ${resendCountdown === 0 ? 'hover:cursor-pointer hover:text-primary' : 'cursor-not-allowed text-gray-400'}`}
                >
                  {resendCountdown === 0 ? 'Resend' : `Resend in ${resendCountdown}s`}
                </div>
              </div>

              <Button className='mt-2' loading={verifyOtp.isLoading} disabled={isVerifyOtpButtonDisabled}>
                Verify OTP
              </Button>
            </div>
          </form>
        </Form>
      )}
    </div>
  )
}
