import { useMutation } from 'react-query'
import { IMutationObj } from '@/utils/types'
import { sendLoginOtp, verifyLoginOtp } from '@/api/services/user.service'
import toast from 'react-hot-toast'

const useSendOtpUserMutation = ({ onSuccess, onError }: IMutationObj) => {
  // Use the useMutation hook to create a new user
  return useMutation(sendLoginOtp, {
    onSuccess: (data: any) => {
      onSuccess && onSuccess(data)
      toast.success('OTP Send Successfully.')
    },
    onError: (err: any) => {
      const message = err?.response?.data?.message
      // toast({
      //   title: 'Please Check the Email.',
      //   description: message || 'Failed to send OTP 😢',
      // })
      onError && onError(message || 'Email not Found.')
    },
  })
}

const useVerifyOtpUserMutation = ({ onSuccess, onError }: IMutationObj) => {
  return useMutation(verifyLoginOtp, {
    onSuccess,
    onError: (err: any) => {
      const message = err.response?.data?.message
      // toast({
      //   title: 'Please Check the OTP.',
      //   description: message || 'Wrong OTP 🤷',
      // })
      onError && onError(message || 'Incorrect OTP.')
    },
  })
}

// const useAuthTokenQuery = ({ ...rest }): any => {
//   const authToken = useRedStore((state) => state.auth.token)
//   const [userToken] = useLocalStorage('token', null)
//   const token = authToken || userToken || ''

//   const { __updateAuthInfo } = useAppAction()

//   if (!token) {
//     if (!window.location.pathname?.includes('auth')) {
//       window.location.pathname = '/auth/logout'
//     }
//   }

//   return useQuery(['authToken', token], () => refetchAuthToken(token), {
//     enabled: !!token,
//     onSuccess: (data: any) => {
//       const tokenUser: ITokenData = data?.data
//       __updateAuthInfo(tokenUser?.email, token)
//       rest?.onSuccess && rest.onSuccess(data)
//     },
//   })
// }

// const useUserFromMDMQuery = ({ ...rest }): any => {
//   // *  Getting email from local storage
//   const [userEmail] = useLocalStorage('login-email', '')
//   const stateEmail = useRedStore((state) => state.auth.userEmail)
//   const email = stateEmail || userEmail

//   // * Query to fetch auth user
//   return useQuery(
//     [`login-email-${email}`, email],
//     () => getMDMUser(email || ''),
//     { enabled: !!email, ...rest }
//   )
// }

// const useLoginUser = () => {
//   const { ...rest } = useAuthTokenQuery({ onSuccess: onTokenUserSetup })
//   const { ...others } = useUserFromMDMQuery({ onSuccess: onMdmUserSetup })
//   const loginUser = useRedStore((state) => state.user)
//   const setLoginDetails = useAppStore((state) => state.setLoginDetails)
//   const updateUser = useRedStore((state) => state._updateUser)

//   function onTokenUserSetup(data: any) {
//     const tokenUser: ITokenData = data?.data
//     if (isValidObject(tokenUser)) {
//       setLoginDetails(tokenUser)
//       const user: ILoginUser = {
//         name: tokenUser?.name || '',
//         email: tokenUser?.email || '',
//         eligibleRoles: tokenUser.roles,
//         dedicatedToClientId: '',
//         createdAt: tokenUser?.createdAt || '',
//         lastLoginAt: tokenUser?.lastLoginAt || '',
//       }
//       if (!loginUser?.email || loginUser?.eligibleRoles?.length <= 0)
//         updateUser(user)
//     }
//   }

//   function onMdmUserSetup(mdmUser: any) {
//     const systemUser: IMDMUserData =
//       mdmUser?.data?.data && mdmUser?.data?.data?.length > 0
//         ? mdmUser.data?.data[0]
//         : {}

//     if (isValidObject(systemUser)) {
//       setLoginDetails(systemUser)
//       updateUser(systemUser)
//     }
//   }

//   return { isLoading: rest?.isLoading || others?.isLoading }
// }

export {
  useSendOtpUserMutation,
  useVerifyOtpUserMutation,
  // useAuthTokenQuery,
  // useUserFromMDMQuery,
  // useLoginUser,
}
